export default {
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    UP_ARROW: 38,
    DOWN_ARROW: 40,
    HOTKEYS: { ALPHANUMERIC: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890-_' },
    PROPERTY_TYPE: {
        MULTI_SELECT: null,
        SINGLE_SELECT: 1,
        ANY_VALUE: 0
    }
}   
;

