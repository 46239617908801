<template>

    <div class="jf-toggler">
        <div class="switch-toggle" :class="{'left': value, 'right': !value}" @click="toggleSelection()">
            <span></span>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'jf-toggler',
        props: ['value'],
        data() {
            return {};
        },
        methods: {
            toggleSelection() {
                this.$emit('input', !this.value);
            }
        }
    }

</script>

<style scoped lang="less">

@import "../../assets/stylesheets/variables";

.jf-toggler {
  display: inline-block;
  .switch-toggle {
    display: inline-block;
    width: 35px;
    padding: 2px;
    border-radius: 20px;
    position: relative;
    height: 19px;
    cursor: pointer;
    vertical-align: middle;
    margin:  -2px 5px 0px 5px;
    transition: all .25s ease-in;
    span {
      display: inline-block;
      background: #fff;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      transition: all .15s ease-in;
    }
  }
}

.switch-toggle {
    &.left {
      background: @greenBGPrimary; // fallback
      span {
        left: 2px;
      }
    }
    &.right {
      background: @grayBGDisabled; // fallback
      span {
        left: 18px;
      }
    }
  }

.jf-toggler-text {
  font-weight: 600;
}

// This is not wrapped by jf-toggler intentionally
.small-toggler {
  .jf-toggler-text {
    font-size: 14px;
    font-weight: 400;
  }
  .switch-toggle {
    width: 22px;
    padding: 2px;
    border-radius: 20px;
    height: 13px;
    span {
      width: 9px;
      height: 9px;
    }
    &.left {
      span {
        left: 2px;
      }
    }
    &.right {
      span {
        left: 11px;
      }
    }
  }
}



</style>
